<template>
  <FullScreenDialog
    v-model="itemTypeDialog"
    title="Famiglia"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in itemTypesTab"
          :key="tab.key"
          @click="$router.push({ name: tab.routeName }).catch(() => {});"
        >{{ tab.title }}</v-tab
        >
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in itemTypesTab" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <router-view></router-view>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="closeDialog()"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid || isLicenseBlocked"
          @click="submitForm"
        >Salva</v-btn>
      </div>
    </template>

<!-- KEEP COMMENT - ONEWARE-562    
  <template v-slot:header-actions>
      <div>
        <StandardMenu
          close-on-content-click
        >
          <template v-slot:activator="{on, attrs}">
            <v-btn icon v-on="on" v-bind="attrs">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-printer</v-icon>
                </v-list-item-icon>
                <v-list-item-content>Stampa</v-list-item-content>
              </v-list-item>
              <v-list-item link>
                <v-list-item-icon class="mr-3">
                  <v-icon color="error">mdi-backspace-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="error--text">Resetta</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </StandardMenu>
      </div>
    </template> -->
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import itemTypesService from '@/services/itemTypes/itemTypes.service.js'
import itemTypeForm from '@/services/itemTypes/itemType.form.js'

export default {
  name: "ItemTypesRegistryEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingItemType: false,
      itemTypeDialog: this.openDialog,
      itemTypesTab: this.tabs,
      itemType: undefined,
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.id = this.$route.params.id
    if(this.id) {
      this.fetchItemType()
    }

    this.changeTabBasedOnRoute(this.$route.name)

    let self = this
    itemTypeForm.on('update', function(data) {
      self.itemType = data.itemType
    })

    self.valid = itemTypeForm.valid
    itemTypeForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/warehouse",
    },
    pathName: {
      type: String,
    },
    tabs: {
      type: Array,
      default: function () {
        return [
          {
            key: 0,
            title: "Generale",
            routeName: "ItemTypesGeneralNewFormTab",
          }
        ];
      },
    },
    filters: {
      type: Array
    }
  },
  methods: {
    changeTabBasedOnRoute(routeName) {
      for(let i = 0; i < this.tabs.length; i++) {
        if(this.tabs[i].routeName == routeName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      itemTypesService.update(this.itemType).then((result) => {
        if (!!this.pathToGoBack) {
          this.close()
        }
      })
    },
    closeDialog() {
      if (this.pathToGoBack) {
        this.close()
      } else {
        this.itemTypeDialog = false
        this.$emit('update:open-dialog', false)
      }
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchItemType() {
      this.loadingItemType = true
      itemTypeForm.resetItemType()
      itemTypesService.get(this.id).then((itemType) => {
        this.loadingItemType = false
        itemTypeForm.updateObject(itemType)
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.itemTypeDialog = newVal;
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>